import unsupportedHtml from "./unsupported.html";
import unsupportedCss from "./unsupported.css";

/**
 * Unsupported features info
 */
type UnsupportedInfo = {
    /** Unsuported features */
    features: string[],

    /** Unsuported expressions */
    expressions: string[]
}

/**
 * Run unsupported browser detection logic. Use contentId to locate textarea with protected scripts. When browser is supported
 * those scripts are inserted as html elements and executed.
 *
 * @param {string} contentId Used to locate textarea with protected scripts and insert target script tags
 * @param {string} logoUrl Url to logo used in unsupported page layout
 */
export const run = (contentId: string, logoUrl: string) => {
    var unsupportedInfo: UnsupportedInfo;

    // support testing using ubc-force-error and ubc-force-ok url tags
    if (window.location.href.indexOf("ubc-force-error") >= 0) {
        unsupportedInfo = {
            features: [ "TEST" ],
            expressions: []
        }
    } else if (window.location.href.indexOf("ubc-force-ok") >= 0) {
        unsupportedInfo = {
            features: [],
            expressions: []
        }
    } else {
        unsupportedInfo = getUnsupportedFeatures();
    }

    if (unsupportedInfo.features.length > 0) {
        handleBrowserUnsupported(unsupportedInfo, logoUrl);
    } else {
        handleBrowserSupported(contentId);
    }
}

/**
 * Handles supported browser case
 * - scripts from textarea are included as script tags to dom and loaded
 *
 * @param {string} contentId Enclosing div id
 */
const handleBrowserSupported = (contentId: string) => {
        // get wrapper div element to operate on.
        const wrapperEl = document.getElementById(contentId);
        if (!wrapperEl) {
            throw new Error("No enclosing div with id "+ contentId);
        }

        // get html content from textarea
        const textAreas = wrapperEl.getElementsByTagName("textarea");
        if (textAreas.length === 0) {
            throw new Error("No textarea inside #"+ contentId);
        }
        const conentToInclude = textAreas[0].childNodes.length === 0 ? "" : textAreas[0].childNodes[0].nodeValue;

        // get all scripts from textarea content
        const tempEl = document.createElement("div");
        tempEl.innerHTML = conentToInclude ?? "";

        // scripts inserted via innerHtml would not execute - https://www.w3.org/TR/2008/WD-html5-20080610/dom.html#innerhtml0
        tempEl.querySelectorAll("script").forEach(scriptEl => {
            // clone element and copy all attributes
            var cloned = document.createElement("script");

            Array.from(scriptEl.attributes).forEach(attr => cloned.setAttribute(attr.name, attr.value));
            wrapperEl.insertAdjacentElement("beforeend", cloned)
        });
}

/**
 * Handle unsupported browser detected case
 * - report unsupported features to AI and console
 * - show unsupported browser screen
 *
 * @param {UnsupportedInfo} unsupported Unsupported features info
 * @param {string} logoUrl Logo url to replace in unsupported screen template
 */
const handleBrowserUnsupported = (unsupported: UnsupportedInfo, logoUrl: string) => {
    // report unsuported features to console and maybe loaded AI
    if (typeof appInsights !== "undefined") {
        appInsights.trackEvent({
            name: "BrowserBlocked"
        }, {
            unsupportedFeatures: unsupported.features,
            unsupportedExpressions: unsupported.expressions
        }); // Send App insights event about browser being blocked
        appInsights.flush();
    }

    // write unsupported info
    if (unsupported.features.length > 0) {
        console.error("Unsupported features detected:", unsupported.features);
    }
    if (unsupported.expressions.length > 0) {
        console.error("Unsupported expressions detected:", unsupported.expressions);
    }

    // replace logo path
    var replacedCss = unsupportedCss.replace('__LOGO_URL__', logoUrl);

    // show unsupported screen
    document.body.innerHTML = unsupportedHtml;

    var styleEl = document.createElement('style');
    styleEl.innerHTML = replacedCss;
    document.body.appendChild(styleEl);
}

/**
 * Check unsupported features and return unsupported runtime list
 *
 * @returns Unsupported features info
 */
const getUnsupportedFeatures = (): UnsupportedInfo => {
    var unsupportedFeatures: string[] = []; // Browser is supported if it has Proxy class and can evaluate ES6 JavaScript
    var unsupportedExpressions: string[] = [];

    const checkIfUnsupported = (expressions: string[]) => {
        let isUnsupported = false;
        for (var i = 0; i < expressions.length; i++) {
            try {
                eval(expressions[i]);
            } catch (e) {
                unsupportedExpressions.push(expressions[i]);
                isUnsupported = true;
            }
        }
        return isUnsupported;
    }

    const es6Features = [ // Source: http://es6-features.org/#Constants
        "() => true",
        "() => { return true; }",
        "(x = 7) => x",
        "(...args) => args",
        "[1, 2, ...[3, 4]]",
        "`${1} + ${2}`",
        "0b111110111",
        "'𠮷'.codePointAt(0)",
        "/\s*/y",
        "var x = 1; var y = 2; const obj = {x, y}",
        "({ ['1' + '1']: 2 })",
        "({ foo(a, b) { return a + b; } })",
        "var [red, blue = 'blue', yellow] = ['red', 'blue', 'yellow']",
        "var { red = 1, blue: { green: green2 }, yellow } = { red: 1, blue: { green: 2 }, yellow: 3 }",
        "([red, blue]) => red + blue",
        "({ set lang(length) { this._lang = length } })",
        "({ get lang() { return 'cz'; } })",
        "for (let n of [1, 2, 3]) {}",
        "function* range() { yield 1; }",
        "async function* load() { yield await Promise.resolve(1); }",
        "var set = new Set(); set.add(1)",
        "var set = new WeakSet",
        "var set = new WeakMap()",
        "' '.repeat(4)",
        "'hello'.startsWith('ello', 1)",
        "'hello'.endsWith('ello')",
        "'hello'.includes('ello')",
        "isNaN(NaN)",
        "Number.isSafeInteger(42)",
        "Number.EPSILON.toString()",
        "Math.trunc(42.7)",
        "Math.sign(-1)",
        "new Promise(resolve => resolve())",
        "new Intl.Collator('de')",
        "new Intl.NumberFormat('en-US')",
        "new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })",
        "new Intl.DateTimeFormat('en-US')",
        "new Uint32Array()",
        "Object.assign({}, {}, {})",
        "[1].find(i => i === 1); [1].findIndex(i => i === 1);",
        "const i = 1",
        "let i = 1",
        "Symbol('3')",
        "class Demo { static property2 = 1; *bar() { yield 1; } } class Demo2 extends Demo {}"
    ];
    const es7Features = [ // Source: https://gist.github.com/rajaramtt/7df3702a04c644b0b62c9a64f48f3dbf#2-ecmascript-2016---es-7
        "2 ** 8",
        "async () => await true",
        "async function* nums() { yield 1; }",
        "Object.getOwnPropertyDescriptors({})",
        "Object.values({})",
        "Object.entries({})",
        "[7, 8].includes(2)",
        "class Demo { property = 0; }"
    ];
    const es8Features = [ // Source: https://gist.github.com/rajaramtt/7df3702a04c644b0b62c9a64f48f3dbf#2-ecmascript-2016---es-7
        "'1699'.padStart(7,0)",
        "[1, 2, 3,]"
    ];
    const es9Features = [ // Source: https://gist.github.com/rajaramtt/7df3702a04c644b0b62c9a64f48f3dbf#2-ecmascript-2016---es-7
        "new Promise((resolve) => resolve()).finally(() => {})",
        "(async () => { for await (let value of [1]) {} })()",
        "const { a, ...other } = { a: 5, b: 7, c: 8 }"
    ];
    const es10Features = [ // Source: https://gist.github.com/rajaramtt/7df3702a04c644b0b62c9a64f48f3dbf#2-ecmascript-2016---es-7
        "[[1], [2]].flat(2)",
        "[[1], [2]].flatMap(i => i)",
        "Object.fromEntries([['one', 1], ['two', 2], ['three', 3]])",
        "'   string    '.trimStart().trimEnd()",
        "Symbol('3').description.toString()",
        "try {} catch {}"
    ];
    const es11Features = [ // Source: https://gist.github.com/rajaramtt/7df3702a04c644b0b62c9a64f48f3dbf#2-ecmascript-2016---es-7
        "BigInt(5)",
        "({a: 5}?.b)",
        "true ?? false",
        "Promise.allSettled([])",
        "'str'.matchAll(/str/g)"
    ];
    if (checkIfUnsupported(es6Features)) {
        unsupportedFeatures.push("ES6");
    }
    if (checkIfUnsupported(es7Features)) {
        unsupportedFeatures.push("ES7");
    }
    if (checkIfUnsupported(es8Features)) {
        unsupportedFeatures.push("ES8");
    }
    if (checkIfUnsupported(es9Features)) {
        unsupportedFeatures.push("ES9");
    }
    if (checkIfUnsupported(es10Features)) {
        unsupportedFeatures.push("ES10");
    }
    if (checkIfUnsupported(es11Features)) {
        unsupportedFeatures.push("ES11");
    }
    if (typeof Proxy != "function") { // Proxy class prototype either does not exist or is not a function.
        unsupportedFeatures.push("Proxy");
    }

    return {
        features: unsupportedFeatures,
        expressions: unsupportedExpressions
    };
}